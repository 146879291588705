@import "node_modules/bootstrap/scss/bootstrap";

.container {
	max-width: 850px;
}

.store {
	display: inline-flex;
	width: 250px;
	top: 2px;
}